import React from 'react';
import Layout from '../components/Layout';

const NotFoundComponent: React.FunctionComponent = () => (
  <Layout>
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </Layout>
);

export default NotFoundComponent;
